import '../../App.css';
import './Redesign.scss';

import Video from '../../video/redesign/hero-video.webm';
import VideoMP4 from '../../video/redesign/hero-video.mp4';
import Character from '../../images/redesign/character.png';
import IconFeat1 from '../../images/redesign/icon-feat-1.png';
import IconFeat2 from '../../images/redesign/icon-feat-2.png';
import IconFeat3 from '../../images/redesign/icon-feat-3.png';
import IconFeat4 from '../../images/redesign/icon-feat-4.png';
import IconFeat5 from '../../images/redesign/icon-feat-5.png';
import Feature1 from '../../images/redesign/feature-1.png';
import Miku from '../../images/redesign/miku.svg';
import MikuBg from '../../images/redesign/target-bg.png';
import ArrowUp from '../../images/redesign/arrow-up.svg';
import ArrowDown from '../../images/redesign/arrow-down.svg';
import Feature2 from '../../images/redesign/feature-2.png';
import Product1 from '../../video/redesign/casinoverse.mp4';
import Product2 from '../../video/redesign/white-room.mp4';
import Product3 from '../../video/redesign/crash-game.mp4';
import TechLogo1 from '../../images/redesign/tech-logo-01.png';
import TechLogo2 from '../../images/redesign/tech-logo-02.png';
import TechIcon1 from '../../images/redesign/tech-icon-01.png';
import TechIcon2 from '../../images/redesign/tech-icon-02.png';
import TechIcon3 from '../../images/redesign/tech-icon-03.png';
import DashboardImage from '../../images/redesign/dashboard-image.png';
import Award from '../../images/redesign/award.png';
import Feedback from '../../images/redesign/feedback.svg';
import FeedbackVideo from '../../images/redesign/feedback.mp4';
import FeedbackM from '../../images/redesign/feedback_mobile.svg';
import FeedbackM2 from '../../images/redesign/feedback_mobile2.svg';
import Team1 from '../../images/redesign/team/1.png';
import Team2 from '../../images/redesign/team/2.png';
import Team3 from '../../images/redesign/team/3.png';
import Team4 from '../../images/redesign/team/4.png';
import Team5 from '../../images/redesign/team/5.svg';
import Team6 from '../../images/redesign/team/6.svg';
import Team7 from '../../images/redesign/team/7.svg';
import Team8 from '../../images/redesign/team/8.svg';
import Team9 from '../../images/redesign/team/9.svg';
import Team10 from '../../images/redesign/team/10.svg';
import Team11 from '../../images/redesign/team/11.svg';
import Bookform1 from '../../images/redesign/bookform-01.png';
import IconLinked from '../../images/redesign/icon-linked.svg';
import IconTele from '../../images/redesign/icon-tele.svg';
import PartAll from '../../images/redesign/part-all.png';
import PartAllM from '../../images/redesign/part-all-m.png';
import ModalImage from '../../images/redesign/modal-image.png';
import TokaGirl from '../../video/redesign/toka_girl_animation.mp4';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { demoContactUs } from '../../services/demoService';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Navigation, Pagination, Parallax } from 'swiper';
import TeamCard from './components/TeamCard';
import ExpandItem from './components/ExpandItem';
import { Modal, ModalBody, ModalDialog } from 'react-bootstrap';
SwiperCore.use([Navigation, Pagination, Parallax]);
import AnimatedNumber from './components/AnimatedNumber';

interface IRedesign {
  showModalDemo: boolean;
  setShowModalDemo: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Redesign({ showModalDemo, setShowModalDemo }: IRedesign) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoState, setVideoState] = useState(true);
  const barRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  function videoHandler() {
    const video = videoRef.current;
    if (video && video instanceof HTMLVideoElement) {
      if (videoState) {
        video.pause();
        setVideoState(false);
      } else {
        video.play();
        setVideoState(true);
      }
    }
  }

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }

        if (entry.target === dashboardRef.current) {
          setIsVisible(entry.isIntersecting);
          if (entry.isIntersecting) {
            const displayItems: string[] = [];
            backofficeItems.forEach((item, index) => {
              const timeoutId = setTimeout(() => {
                displayItems.push(item);
                setVisibleItems([...displayItems]);
              }, 500 * (index + 1));
            });
          } else {
            setVisibleItems([]);
          }
        }
      });
    };

    observer = new IntersectionObserver(handleIntersect, { threshold: 0.5 });

    const observeTargets = [barRef, loaderRef, dashboardRef];
    observeTargets.forEach((targetRef) => {
      if (observer && targetRef.current) {
        observer.observe(targetRef.current);
      }
    });

    if (dashboardRef.current) {
      const displayItems: string[] = [];
      backofficeItems.forEach((item, index) => {
        const timeoutId = setTimeout(() => {
          displayItems.push(item);
          setVisibleItems([...displayItems]);
        }, 1000 * (index + 1));

        return () => clearTimeout(timeoutId);
      });
    }

    return () => {
      if (observer) {
        observeTargets.forEach((targetRef) => {
          if (observer && targetRef.current) {
            observer.unobserve(targetRef.current);
          }
        });
      }
    };
  }, []);

  const [isFirstImage, setIsFirstImage] = useState(true);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFirstImage((prevState) => !prevState);
    }, 20000);

    return () => clearInterval(intervalId);
  }, []);

  const currentFeedbackSrc = isFirstImage ? FeedbackM : FeedbackM2;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    aboutYourCompany: ''
  });

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    demoContactUs(formData).then(() => {
      setShowModalDemo(false);
      setShowThankYouModal(true);
    });
  };

  const [expandActive, setExpandActive] = useState<string[]>([]);

  useEffect(() => {
    setExpandActive(['active', '', '']);
  }, []);

  const [showModalVideo, setShowModalVideo] = useState(false);
  const [showModalVideo2, setShowModalVideo2] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);

  const expandItems = [
    [
      'active',
      Product1,
      'Casinoverse',
      'In our premium solution we are offering a full-blown casino in the way that you envision it. We have partnered with world renowned architects to make sure we bring your visions and desires to life.'
    ],
    [
      'active',
      Product2,
      'VIP ROOMS',
      'The VIP rooms act as a mini casino setting. In this solution we offer from 5-7 table games accompanied by 10-15 slot machines. Additionally, each room is built with its own entertainment floor as well as a social area for players to network.'
    ],
    [
      'active',
      Product3,
      'Crash games',
      'Toka Originals offers single and multiplayer crash game experiences, both featuring full customization with unique themes and immersive 3D environments.'
    ]
  ];

  const team__items = [
    [
      Team1,
      'Todor Minev',
      'CEO',
      [
        '-Serial entrepreneur',
        '-Founder of Hyperzon & grew to $130M+ annual sales',
        '-Partner at Animajor Studios',
        '-Professor at The American University in Bulgaria'
      ],
      'https://www.linkedin.com/in/tminev/'
    ],
    [
      Team2,
      'Daniel Harding',
      'COO',
      [
        '-Fin-tech founder & multi-exited entrepreneur',
        '-Industry-leading operational expert, with specialism in e-comm & payment solutions',
        '-Founder & Chairman of Sonorous Group',
        '-Serial investor'
      ],
      'https://www.linkedin.com/in/daniel-harding-sg/'
    ],
    [
      Team3,
      'Stanislav Milev',
      'CTO',
      [
        '-15+ years of tech development experience',
        '-Founder of Stam Soft',
        '-Partnered with global companies (AT&T, Travelex, ProSieben, Qello Holdings, etc.)'
      ],
      'https://www.linkedin.com/in/stanislav-milev-2733a94/'
    ],
    [
      Team4,
      'Yav Bonev',
      'CCO',
      [
        '-Unreal Engine & VFX expert',
        '-Founder of Animajor Studios',
        '-150+ VFX projects for global brands (Samsung, Heineken, Bulgari, Dove, etc.)'
      ],
      'https://www.linkedin.com/in/yavbonev/'
    ],
    [
      Team5,
      'George Pavlov',
      'CFO',
      [
        '-25+ years of experience in finance & strategy',
        '-CFO of Hyperzon',
        '-Partner at Brand Labs',
        '-GM, Global Exports at FICOSOTA'
      ],
      'https://www.linkedin.com/in/george-pavlov-0a23742/'
    ],
    [
      Team6,
      'Ignas Fiodorovas',
      'CMO',
      ['-Serial e-comm and web3 entrepreneur', '-Founder of Jellyjim', '-Founder of Popdrips'],
      'https://www.linkedin.com/in/ignas-fiodorovas-24aa71198/'
    ],
    // [
    //   Team7,
    //   'Jeong Hoon (Jay) Ha',
    //   'CSO',
    //   [
    //     '-Investor in 80+ web2 & web3 companies',
    //     '-Co-Founder & Asia Partner at IP3',
    //     '-General Partner at Zingforce Ventures',
    //     '-Advisory Board Member at Purpose Venture Capital'
    //   ],
    //   'https://www.linkedin.com/in/jeong-hoon-jay-ha-2236b976/'
    // ],
    [
      Team8,
      'Frédéric Winckler',
      'Casino Operations & Marketing',
      [
        '-CMO and Head of Entertainment & Retail at Melco Resorts & Entertainment - the worldwide leader in premium integrated resorts and casinos',
        '-Global CMO at Louis Vuitton',
        '-Head of Global Communications at WPP'
      ],
      'https://www.linkedin.com/in/fredericwinckler/'
    ],
    [
      Team9,
      'Vid Daolio',
      'Casino Gamification',
      [
        '-iGaming & gamification expert at Blue Ocean Gaming',
        '-Project Manager at GameArt',
        '-Managed multiple web3 gamification projects for global casino clients'
      ],
      'https://www.linkedin.com/in/vid-daolio-477819b6/'
    ],
    [
      Team10,
      'Dmitry Belianin',
      'Sports Betting Marketing Strategist',
      [
        '-17+ years in sports betting marketing, showcasing expertise in gaming, team leadership, and profit growth.',
        '-Proven track record in implementing high-impact marketing, digital, and product strategies for leading gaming companies.',
        '-Recognized for global team management, innovation, and successful marketing campaigns in the competitive gaming sector.'
      ],
      'https://www.linkedin.com/in/dmitrybelianin/'
    ],
    // [
    //   Team11,
    //   'Stoyan Kotsev',
    //   'Project Owner',
    //   [
    //     '- Ex Professional Gamer',
    //     '- Tech Enthusiast',
    //     '- Trend and Concept professional that has worked with leading companies such as Bosch, Philips, Ferrero'
    //   ],
    //   'https://www.linkedin.com/in/stoyankotsev/'
    // ]
  ];

  const [visibleItems, setVisibleItems] = useState<string[]>([]);

  const backofficeItems = [
    'Dashboard summaries',
    'Players management',
    'Responsive gaming management',
    'Real-time monitoring',
    'Blacklists and whitelists',
    'Customer Support tool'
  ];

  const videoGirlRef = useRef<HTMLVideoElement>();

  useEffect(() => {
    const video = videoGirlRef.current;

    if (video) {
      const handleEnded = () => {
        setTimeout(() => {
          video.currentTime = 0;
          video.play();
        }, 1500);
      };

      video.addEventListener('ended', handleEnded);

      return () => {
        video.removeEventListener('ended', handleEnded);
      };
    }
  }, []);

  return (
    <>
      <ModalDialog className={`${showModalDemo && 'open'}`}>
        <Modal show={showModalDemo} onHide={() => setShowModalDemo(false)}>
          <button className='dmodal__close modal-close' data-modal='modal-demo' onClick={() => setShowModalDemo(false)}>
            <svg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 12 12' fill='none'>
              <path
                d='M11.8242 10.9752C11.8799 11.031 11.9241 11.0971 11.9543 11.17C11.9845 11.2428 12 11.3209 12 11.3997C12 11.4785 11.9845 11.5566 11.9543 11.6294C11.9241 11.7023 11.8799 11.7684 11.8242 11.8242C11.7684 11.8799 11.7023 11.9241 11.6294 11.9543C11.5566 11.9845 11.4785 12 11.3997 12C11.3209 12 11.2428 11.9845 11.17 11.9543C11.0971 11.9241 11.031 11.8799 10.9752 11.8242L6 6.8482L1.02478 11.8242C0.912198 11.9368 0.75951 12 0.6003 12C0.441091 12 0.288402 11.9368 0.175824 11.8242C0.0632457 11.7116 3.1384e-09 11.5589 0 11.3997C-3.1384e-09 11.2405 0.0632457 11.0878 0.175824 10.9752L5.1518 6L0.175824 1.02478C0.0632457 0.912198 -1.1862e-09 0.75951 0 0.6003C1.1862e-09 0.441091 0.0632457 0.288402 0.175824 0.175824C0.288402 0.0632457 0.441091 1.1862e-09 0.6003 0C0.75951 -1.1862e-09 0.912198 0.0632457 1.02478 0.175824L6 5.1518L10.9752 0.175824C11.0878 0.0632457 11.2405 -3.1384e-09 11.3997 0C11.5589 3.1384e-09 11.7116 0.0632457 11.8242 0.175824C11.9368 0.288402 12 0.441091 12 0.6003C12 0.75951 11.9368 0.912198 11.8242 1.02478L6.8482 6L11.8242 10.9752Z'
                fill='white'
              />
            </svg>
          </button>

          <div className='dmodal__content form-show-default'>
            <div className='dmodal__content__text'>
              <div className='global__heading'>
                <h4 className='global__heading-title brand-light--color size-m'>Begin your journey</h4>
                <span className='global__heading-subtitle brand-light--color size-m'>
                  Enter your credentials to book a demo
                </span>
              </div>
              <div className='global__heading' hidden>
                <h4 className='global__heading-title brand-light--color size-m'>Thank you for your submission!</h4>
                <p className='global__heading-text brand-light--color size-m'>
                  We’ll be in touch with you shortly.
                  {'\n'}
                  {'\n'}
                  Toka Team
                </p>
              </div>
            </div>
            <form className='dmodal__content__form' onSubmit={(e) => handleSubmit(e)}>
              <label className='dmodal__content__form-field'>
                <span>Name/Company*</span>
                <input
                  type='text'
                  name='name'
                  placeholder='Your Name'
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
              </label>
              <label className='dmodal__content__form-field'>
                <span>Email*</span>
                <input
                  type='email'
                  name='email'
                  placeholder='Your Email Address'
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
              </label>
              <label className='dmodal__content__form-field'>
                <span>About your company*</span>
                <textarea
                  name='aboutYourCompany'
                  required
                  value={formData.aboutYourCompany}
                  onChange={handleChange}
                ></textarea>
              </label>
              <button className='dmodal__content__form-btn' type='submit'>
                Submit
              </button>
            </form>
          </div>
          <div className='dmodal__content form-show-success'>
            <div className='dmodal__content__text'>
              <div className='global__heading'>
                <h4 className='global__heading-title brand-light--color size-m'>Thank you for your submission!</h4>
                <p className='global__heading-text brand-light--color size-m'>
                  We’ll be in touch with you shortly.
                  {'\n'}
                  {'\n'}
                  Toka Team
                </p>
              </div>
            </div>
          </div>

          <div className='dmodal__image'>
            <img
              className='dmodal__image--placeholder'
              src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 646'%3E%3C/svg%3E"
            />
            <img className='dmodal__image--real' loading='lazy' src={ModalImage} />
          </div>
        </Modal>
      </ModalDialog>

      <ModalDialog className={`${showThankYouModal && 'open'}`}>
        <Modal show={showThankYouModal} onHide={() => setShowThankYouModal(false)} className='thank-you'>
          <button
            className='dmodal__close modal-close'
            data-modal='modal-demo'
            onClick={() => setShowThankYouModal(false)}
          >
            <svg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 12 12' fill='none'>
              <path
                d='M11.8242 10.9752C11.8799 11.031 11.9241 11.0971 11.9543 11.17C11.9845 11.2428 12 11.3209 12 11.3997C12 11.4785 11.9845 11.5566 11.9543 11.6294C11.9241 11.7023 11.8799 11.7684 11.8242 11.8242C11.7684 11.8799 11.7023 11.9241 11.6294 11.9543C11.5566 11.9845 11.4785 12 11.3997 12C11.3209 12 11.2428 11.9845 11.17 11.9543C11.0971 11.9241 11.031 11.8799 10.9752 11.8242L6 6.8482L1.02478 11.8242C0.912198 11.9368 0.75951 12 0.6003 12C0.441091 12 0.288402 11.9368 0.175824 11.8242C0.0632457 11.7116 3.1384e-09 11.5589 0 11.3997C-3.1384e-09 11.2405 0.0632457 11.0878 0.175824 10.9752L5.1518 6L0.175824 1.02478C0.0632457 0.912198 -1.1862e-09 0.75951 0 0.6003C1.1862e-09 0.441091 0.0632457 0.288402 0.175824 0.175824C0.288402 0.0632457 0.441091 1.1862e-09 0.6003 0C0.75951 -1.1862e-09 0.912198 0.0632457 1.02478 0.175824L6 5.1518L10.9752 0.175824C11.0878 0.0632457 11.2405 -3.1384e-09 11.3997 0C11.5589 3.1384e-09 11.7116 0.0632457 11.8242 0.175824C11.9368 0.288402 12 0.441091 12 0.6003C12 0.75951 11.9368 0.912198 11.8242 1.02478L6.8482 6L11.8242 10.9752Z'
                fill='white'
              />
            </svg>
          </button>
          <div className='dmodal__content form-show-default'>
            <div className='dmodal__content__text'>
              <div className='global__heading'>
                <h4 className='global__heading-title brand-light--color size-m'>Thank you for your submission!</h4>
                <p className='global__heading-text brand-light--color size-m'>
                  We’ll be in touch with you shortly.
                  {'\n'}
                  {'\n'}
                  Toka Team
                </p>
              </div>
            </div>
          </div>

          <div className='dmodal__image'>
            <img
              className='dmodal__image--placeholder'
              src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 666 646'%3E%3C/svg%3E"
            />
            <img className='dmodal__image--real' loading='lazy' src={ModalImage} />
          </div>
        </Modal>
      </ModalDialog>

      <ModalDialog className={`${showModalVideo && 'open'}`}>
        <Modal show={showModalVideo} onHide={() => setShowModalVideo(false)} className='video'>
          <ModalBody className='video'>
            <button
              className='dmodal__close modal-close'
              data-video='1'
              data-modal='modal-video'
              onClick={() => setShowModalVideo(false)}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 12 12' fill='none'>
                <path
                  d='M11.8242 10.9752C11.8799 11.031 11.9241 11.0971 11.9543 11.17C11.9845 11.2428 12 11.3209 12 11.3997C12 11.4785 11.9845 11.5566 11.9543 11.6294C11.9241 11.7023 11.8799 11.7684 11.8242 11.8242C11.7684 11.8799 11.7023 11.9241 11.6294 11.9543C11.5566 11.9845 11.4785 12 11.3997 12C11.3209 12 11.2428 11.9845 11.17 11.9543C11.0971 11.9241 11.031 11.8799 10.9752 11.8242L6 6.8482L1.02478 11.8242C0.912198 11.9368 0.75951 12 0.6003 12C0.441091 12 0.288402 11.9368 0.175824 11.8242C0.0632457 11.7116 3.1384e-09 11.5589 0 11.3997C-3.1384e-09 11.2405 0.0632457 11.0878 0.175824 10.9752L5.1518 6L0.175824 1.02478C0.0632457 0.912198 -1.1862e-09 0.75951 0 0.6003C1.1862e-09 0.441091 0.0632457 0.288402 0.175824 0.175824C0.288402 0.0632457 0.441091 1.1862e-09 0.6003 0C0.75951 -1.1862e-09 0.912198 0.0632457 1.02478 0.175824L6 5.1518L10.9752 0.175824C11.0878 0.0632457 11.2405 -3.1384e-09 11.3997 0C11.5589 3.1384e-09 11.7116 0.0632457 11.8242 0.175824C11.9368 0.288402 12 0.441091 12 0.6003C12 0.75951 11.9368 0.912198 11.8242 1.02478L6.8482 6L11.8242 10.9752Z'
                  fill='white'
                />
              </svg>
            </button>

            <iframe
              width='780'
              height='315'
              src='https://www.youtube.com/embed/7gZGhCVKFyc?si=HFA4Kmo_LEfyVyq7?&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </ModalBody>
        </Modal>
      </ModalDialog>
      <ModalDialog className={`${showModalVideo2 && 'open'}`}>
        <Modal show={showModalVideo2} onHide={() => setShowModalVideo2(false)} className='video'>
          <ModalBody className='video'>
            <button
              className='dmodal__close modal-close'
              data-video='1'
              data-modal='modal-video2'
              onClick={() => setShowModalVideo2(false)}
            >
              <svg xmlns='http://www.w3.org/2000/svg' width='12' viewBox='0 0 12 12' fill='none'>
                <path
                  d='M11.8242 10.9752C11.8799 11.031 11.9241 11.0971 11.9543 11.17C11.9845 11.2428 12 11.3209 12 11.3997C12 11.4785 11.9845 11.5566 11.9543 11.6294C11.9241 11.7023 11.8799 11.7684 11.8242 11.8242C11.7684 11.8799 11.7023 11.9241 11.6294 11.9543C11.5566 11.9845 11.4785 12 11.3997 12C11.3209 12 11.2428 11.9845 11.17 11.9543C11.0971 11.9241 11.031 11.8799 10.9752 11.8242L6 6.8482L1.02478 11.8242C0.912198 11.9368 0.75951 12 0.6003 12C0.441091 12 0.288402 11.9368 0.175824 11.8242C0.0632457 11.7116 3.1384e-09 11.5589 0 11.3997C-3.1384e-09 11.2405 0.0632457 11.0878 0.175824 10.9752L5.1518 6L0.175824 1.02478C0.0632457 0.912198 -1.1862e-09 0.75951 0 0.6003C1.1862e-09 0.441091 0.0632457 0.288402 0.175824 0.175824C0.288402 0.0632457 0.441091 1.1862e-09 0.6003 0C0.75951 -1.1862e-09 0.912198 0.0632457 1.02478 0.175824L6 5.1518L10.9752 0.175824C11.0878 0.0632457 11.2405 -3.1384e-09 11.3997 0C11.5589 3.1384e-09 11.7116 0.0632457 11.8242 0.175824C11.9368 0.288402 12 0.441091 12 0.6003C12 0.75951 11.9368 0.912198 11.8242 1.02478L6.8482 6L11.8242 10.9752Z'
                  fill='white'
                />
              </svg>
            </button>

            <iframe
              width='780'
              height='315'
              src='https://www.youtube.com/embed/HPuf46aB8Yg?si=ZwxlkVIsg8cH4L157?&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer'
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
            ></iframe>
          </ModalBody>
        </Modal>
      </ModalDialog>

      <section id='top' className='hero'>
        <div onClick={videoHandler}>
          <video className='hero__media' preload='auto' loop muted playsInline autoPlay data-poster='' ref={videoRef}>
            <source src={Video} type='video/webm' />
            <source src={VideoMP4} type='video/mp4' />
          </video>
        </div>

        <div className='hero__content'>
          <div className='hero__content__text'>
            <span className='hero__content__text--line1'>Welcome to the</span>
            <span className='hero__content__text--line2'>Casinoverse</span>
          </div>
        </div>
      </section>
      <section
        id='about'
        className='about'
        // style={"z-index:5"}
      >
        <div className='about__wrapper container-box-lg spacing-x-02 '>
          <div className='about__wrapper__row1'>
            <div className='about__wrapper__row1__box '>
              <div className='global__heading '>
                <h4 className='global__heading-title brand-light--color size-m'>WHAT WE DO?</h4>
                <p className='global__heading-text brand-light--color size-l'>
                  We are setting a new standard for casino operators by integrating high-tech gamified solutions to
                  bring new generation of players, resulting in lower acquisition costs and higher retentions rates.
                </p>
              </div>
            </div>
          </div>

          <div className='about__wrapper__row2'>
            <div className='about__wrapper__row2__box '>
              <div className='global__heading '>
                <h4 className='global__heading-title brand-light--color size-m'>BE DIFFERENT</h4>
                <span className='global__heading-subtitle brand-light--color size-m'>
                  SET APART FROM OTHER OPERATORS
                </span>
                <p className='global__heading-text brand-light--color size-l'>
                  All online casinos look and feel the same; they even offer the same games and live experiences. The
                  only aspect that sets them apart is their marketing strategies, but even then, they lack customer
                  loyalty. By introducing a new layer of entertainment through social interactions and immersive
                  experiences, we'll help your brand stand out among all others, attracting the next generation of
                  iGaming players.
                </p>
              </div>
            </div>

            <div className='about__char'>
              <div className='about__char__wrapper'>
                <img
                  className='about__char__image--placeholder'
                  src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 9'%3E%3C/svg%3E"
                />
                <img className='about__char__image--real' loading='lazy' src={Character} alt='' />
                <div className='about__char__button'>
                  <a
                    onClick={() => setShowModalVideo(true)}
                    data-modal='modal-video'
                    data-video-src='https://www.youtube.com/embed/7gZGhCVKFyc?si=HFA4Kmo_LEfyVyq7?&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer'
                    className='modal-open about__char__button--link'
                  >
                    <svg xmlns='http://www.w3.org/2000/svg' width='40' viewBox='0 -960 960 960'>
                      <path
                        fill='currentColor'
                        d='M298-246v-475q0-19.849 13.714-32.925Q325.429-767 344.048-767q4.881 0 11.416 1.5 6.536 1.5 13.415 4.429L742-521q11 6 16.5 16.342Q764-494.316 764-483t-5.75 21.658Q752.5-451 742-446L368.879-205.929Q362-202 355.221-201q-6.779 1-11.578 1-18.357 0-32-12.35Q298-224.701 298-246Z'
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id='about2'
        className='features'
        // style="z-index:1"
      >
        <div className='features__wrapper spacing-x-02'>
          <div className='features__row row-1'>
            <div className='left'>
              <div className='features__row__image_bg'>
                <img src={MikuBg} loading='lazy' alt='' />
              </div>
              <div className='overlay'></div>
              <div className='features__row__image'>
                <img src={Miku} loading='lazy' alt='' />
              </div>
              <div className='features__row__wave'>
                <div className='new-players'>
                  <p>New players</p>
                </div>
                <div className='numbers'>
                  <div className='first'>
                    <AnimatedNumber endValue={17463} showZero={false} duration={3000} />
                  </div>

                  <div className='second'>
                    <img src={ArrowUp}></img>
                    <AnimatedNumber endValue={24.53} showZero={true} duration={3000} />
                    {'%'}
                  </div>
                </div>
                <div className='progress-bar1'>
                  <div className='progress-container'>
                    <div ref={barRef} className={`bar ${isVisible ? 'animate' : ''}`}></div>
                  </div>
                </div>
              </div>
              <div className='features__row__acquisition'>
                <div className='cost'>
                  <p>Acquisition Cost</p>
                </div>
                <div className='numbers'>
                  <div className='second'>
                    <img src={ArrowDown}></img>
                    {'-'}
                    <AnimatedNumber endValue={45.54} showZero={true} duration={3000} />
                    {'%'}
                  </div>
                </div>
              </div>
            </div>

            <div className='features__row__text'>
              <div className='global__heading '>
                <h4 className='global__heading-title brand-light--color size-m'>NEXT WAVE OF PLAYERS</h4>
                <p className='global__heading-text brand-light--color size-l'>
                  Our products are designed to appeal to the tech-savvy generation, particularly those who have grown up
                  enjoying immersive video games such as GTA, Fortnite and Call of Duty. It offers a multiplayer social
                  experience similar to popular social platforms, enabling users to easily connect and interact with
                  each other.
                  {'\n'}
                  {'\n'}
                  The immersive environments open up new opportunities for streamers and influencers to interact with
                  their audiences in a more natural way, creating a sustainable stream of new casino players.
                  {'\n'}
                  {'\n'}
                  Overall, we believe that being the first to offer such experiences would bring new players to the
                  industry and significantly reduce acquisition costs.
                </p>
              </div>
            </div>
          </div>
          <div className='features__row row-2'>
            <div className='features__row__text'>
              <div className='global__heading '>
                <h4 className='global__heading-title brand-light--color size-m'>HIGHER RETENTION</h4>
                <p className='global__heading-text brand-light--color size-l'>
                  Introducing new aspects to iGaming industry will keep players coming in for more dopamine spikes,
                  resulting in higher retention rates.
                  {'\n'}
                  {'\n'}
                  As video gaming experts we are integrating the industry's most effective and time-tested retention
                  strategies into the iGaming world.
                </p>
              </div>

              <div className='global__heading '>
                <span className='global__heading-subtitle brand-red--color size-m'>KEY ELEMENTS</span>
                <p className='global__heading-text brand-light--color size-l'>
                  These elements have been instrumental in the video gaming industry for decades in maintaining players
                  engagement.
                </p>
              </div>

              <div className='features__row__text__icons'>
                <div className='features__row__text__icons--item'>
                  <img src={IconFeat1} loading='lazy' alt='' />
                  <span>Multiplayer</span>
                </div>
                <div className='features__row__text__icons--item'>
                  <img src={IconFeat2} loading='lazy' alt='' />
                  <span>Customizations</span>
                </div>
                <div className='features__row__text__icons--item'>
                  <img src={IconFeat3} loading='lazy' alt='' />
                  <span>Social elements</span>
                </div>
                <div className='features__row__text__icons--item'>
                  <img src={IconFeat4} loading='lazy' alt='' />
                  <span>Tournaments</span>
                </div>
                <div className='features__row__text__icons--item'>
                  <img src={IconFeat5} loading='lazy' alt='' />
                  <span>Gamification mechanics</span>
                </div>
              </div>
            </div>
            <div className='features__row__image'>
              <video
                className='video'
                autoPlay
                muted
                playsInline
                width={'100%'}
                height={'100%'}
                ref={videoGirlRef as React.MutableRefObject<HTMLVideoElement>}
              >
                <>
                  <source src={TokaGirl} type='video/mp4; codecs="hvc1"' />
                  <source src={TokaGirl} type='video/webm' />
                </>
              </video>
            </div>
          </div>
        </div>
      </section>

      <section className='products' id='products'>
        <div className='products__wrapper spacing-x-02'>
          <div className='global__heading align-left-md-center spacing-y-03'>
            <span className='global__heading-title brand-dark--color size-m'>OUR PRODUCTS</span>
            <p className='global__heading-subtitle brand-dark--color size-m case-normal bold-normal'>
              Fully customizable white-label solutions with single API integration.
            </p>
          </div>
          <div className='expandcard'>
            <ul className='expandcard_acc'>
              {expandItems.map(([active, media, title, text], index) => {
                return (
                  <ExpandItem
                    key={index}
                    index={index}
                    media={media}
                    title={title}
                    text={text}
                    expandActive={expandActive}
                    setExpandActive={setExpandActive}
                  ></ExpandItem>
                );
              })}
            </ul>
          </div>
        </div>
      </section>

      <section className='tech' id='tech'>
        <div className='tech__wrapper spacing-x-02 spacing-y-03'>
          <div className='tech__text'>
            <div className='global__heading spacing-y-03'>
              <span className='global__heading-title brand-red--color size-m'>The technology</span>
              <p className='global__heading-text brand-light--color size-l bold-light'>
                Tokacity has achieved something else that nobody has done before, which is bringing triple a graphics
                provided by <b>Unreal Engine</b> to any device which has a screen and internet connection using the{' '}
                <b>Microsoft Azure</b> infrastructure.
                {'\n'}
                {'\n'}
                We have developed our propitiatory pixel streaming technology which allows players from all over the
                world to meet and interact in immersive environments without a need of sophisticated gaming hardware. We
                are constantly improving the user experience, speed and connectivity throughout our platform.
              </p>
            </div>
            <div className='tech__text--logos'>
              <img src={TechLogo1} alt='' loading='lazy' />
              <img src={TechLogo2} alt='' loading='lazy' />
            </div>
          </div>
          <div className='tech__box'>
            <div className='tech__box__in'>
              <div className='tech__box__in--item'>
                <i>
                  <img src={TechIcon1} loading='lazy' alt='' />
                </i>
                <span>Any device access</span>
                <p>
                  Regardless if you want to play on the go or at home, you can join our Triple A casinoverse from your
                  phone, tablet or computer.
                </p>
              </div>
              <div className='tech__box__in--item'>
                <i>
                  <img src={TechIcon2} loading='lazy' alt='' />
                </i>
                <span>Plug and play</span>
                <p>A hassle-free and efficient way for integrating Tokacity games into your online casino.</p>
              </div>
              <div className='tech__box__in--item'>
                <i>
                  <img src={TechIcon3} loading='lazy' alt='' />
                </i>
                <span>Maximum safety</span>
                <p>
                  Our games are directly streamed to devices, ensuring maximum protection with no data leaks and full
                  encryption.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='dashboard' ref={dashboardRef}>
        <div className='dashboard__wrapper spacing-x-02 spacing-y-03'>
          <div className='dashboard__demo'>
            <img src={DashboardImage} loading='lazy' alt='' />
            <span className='dashboard__demo--note'>*Fictional data for visualization.</span>
          </div>
          <div className='dashboard__text'>
            <span className='dashboard__text--header'>Back office</span>
            <div className='dashboard__text--list'>
              {visibleItems.map((item, index) => (
                <div key={index} className='dashboard__text--list-item'>
                  <div className='wrapper'>
                    <svg className='checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
                      <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />
                      <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
                    </svg>
                  </div>
                  <span>{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className='awards'>
        <div className='awards__wrapper spacing-x-02 spacing-y-03'>
          <div className='awards__text'>
            <div className='global__heading spacing-y-03 awards__text__txt'>
              <span className='global__heading-title brand-red--color size-m'>ACCOMPLISHMENTS</span>
              <p className='global__heading-text brand-dark--color size-l bold-light'>
                We created a fully customized game for the BFTH Arena competition, organized by <b>BetConstruct</b>.
                There were more than 50 industry-leading game providers in the competition, so we take great pride in
                receiving the Best Game Design Award.
                {'\n'}
                {'\n'}
                The award came with a reward fund of 555,000 FTN tokens, approximately $750,000, which will be used for
                marketing activities. The game will be distributed throughout all of BetConstruct's networks and
                channels.
              </p>
            </div>
            <div className='awards__text__image'>
              <img
                className='awards__text__image--placeholder'
                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 780 763'%3E%3C/svg%3E"
              />
              <img className='awards__text__image--real' loading='lazy' src={Award} />
            </div>
          </div>
          <div className='awards__btns'>
            <a
              onClick={() => setShowModalVideo2(true)}
              data-modal='modal-video2'
              data-video-src='https://www.youtube.com/embed/HPuf46aB8Yg?si=ZwxlkVIsg8cH4L157?&autoplay=1&enablejsapi=1&version=3&playerapiid=ytplayer'
              className='modal-open awards__btns--link1'
            >
              <span>Play Video</span>
            </a>
            <a href='/game-sheet.pdf' download className='awards__btns--link2'>
              <span>DOWNLOAD GAME SHEET</span>
            </a>
          </div>
        </div>
      </section>

      <section className='feedback'>
        <div className='feedback__wrapper spacing-x-02 spacing-y-02'>
          <div className='feedback__col1'>
            <div className='feedback__col1__head'>
              <div className='global__heading spacing-y-03'>
                <span className='global__heading-title brand-light--color size-m'>Feedback</span>
                <p className='global__heading-text brand-light--color size-l bold-light'>
                  We had our games tested by streamers and users from all over the world, and here are some of the
                  things they say:
                </p>
              </div>
            </div>
          </div>
          <div
            className={`feedback__col2 ${
              currentFeedbackSrc === FeedbackM && window.screen.width < 1136 ? 'margin' : ''
            }`}
          >
            <div className='feedback_assets hide-md'>
              <img className='hide-md' src={Feedback} loading='lazy' alt='' />
              <video className='hide-md' preload='auto' loop muted playsInline autoPlay data-poster=''>
                <source src={FeedbackVideo} type='video/mp4' />
              </video>
            </div>

            <div
              className='recommend'
              ref={loaderRef}
              hidden={!(currentFeedbackSrc === FeedbackM) && window.screen.width < 1136}
            >
              <div className='left'>
                <p>9 of 10</p>
                <span>would recommend</span>
              </div>
              <div className='right'>
                <div className='loader'>
                  <div className='loader-container'>
                    <svg className={`loader ${isVisible ? 'animate' : ''}`} viewBox='0 0 100 100'>
                      <circle className='loader-circle' cx='50' cy='50' r='40'></circle>
                      <foreignObject x='25' y='30' width='80' height='80' className='loader-component'>
                        <div>
                          <AnimatedNumber endValue={90} showZero={false} duration={1000} />
                          <span>{'%'}</span>
                        </div>
                      </foreignObject>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='feedback_assets show-on-md'>
              <video
                className='show-on-md'
                preload='auto'
                loop
                muted
                playsInline
                autoPlay
                data-poster=''
                hidden={currentFeedbackSrc === FeedbackM}
              >
                <source src={FeedbackVideo} type='video/mp4' />
              </video>
              <img
                className='show-on-md'
                src={currentFeedbackSrc}
                loading='lazy'
                alt='Feedback'
                style={{ padding: '0 20px' }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className='bookf'>
        <div className='bookf__wrapper'>
          <div className='bookf__box ajaxform'>
            <div className='bookf__box__in form-show-default'>
              <div className='bookf__box__text'>
                <div className='global__heading'>
                  <h4 className='global__heading-title brand-light--color size-m'>BOOK DEMO</h4>
                  <span className='global__heading-text brand-light--color size-l'>
                    Prepare to take your casino to new heights of entertainment. Fill out the form and book a
                    personalized demo session today!
                  </span>
                </div>
              </div>

              <form className='bookf__box__form' onSubmit={handleSubmit}>
                <label className='bookf__box__form-field'>
                  <span>Name/Company*</span>
                  <input
                    type='text'
                    name='name'
                    placeholder=''
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                </label>
                <label className='bookf__box__form-field'>
                  <span>Email*</span>
                  <input
                    type='email'
                    name='email'
                    placeholder=''
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </label>
                <label className='bookf__box__form-field'>
                  <span>About your company*</span>
                  <textarea
                    name='aboutYourCompany'
                    required
                    value={formData.aboutYourCompany}
                    onChange={handleChange}
                  ></textarea>
                </label>
                <button className='bookf__box__form-btn' type='submit'>
                  Contact us
                </button>
              </form>
            </div>

            <div className='bookf__box__in form-show-success'>
              <div className='bookf__box__text'>
                <div className='global__heading'>
                  <h4 className='global__heading-title brand-light--color size-m'>Thank you for your submission!</h4>
                  <p className='global__heading-text brand-light--color size-m'>
                    We’ll be in touch with you shortly.
                    {'\n'}
                    {'\n'}
                    Toka Team
                  </p>
                </div>
              </div>
            </div>

            <div className='bookf__box__image'>
              <img
                className='bookf__box__image--placeholder'
                src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 645 707'%3E%3C/svg%3E"
              />
              <img className='bookf__box__image--real' loading='lazy' src={Bookform1} />
            </div>
          </div>
        </div>
      </section>
      <div className='back-dark'>
        <section className='team'>
          <div className='team__wrapper'>
            <div className='team__heading'>
              <div className='global__heading'>
                <h4 className='global__heading-title brand-red--color size-m'>Leadership team</h4>
              </div>
              {/* <div className='team__heading__controls hide-md'>
                <div className='team__heading__controls--prev'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -960 960 960'>
                    <path
                      fill='currentColor'
                      d='m418.153-480 162.923 162.924q8.308 8.307 8.5 20.884.193 12.576-8.5 21.268-8.692 8.693-21.076 8.693t-21.076-8.693L359.155-454.693q-5.616-5.615-7.923-11.846-2.308-6.23-2.308-13.461t2.308-13.461q2.307-6.231 7.923-11.846l179.769-179.769q8.307-8.308 20.884-8.5 12.576-.193 21.268 8.5 8.693 8.692 8.693 21.076t-8.693 21.076L418.153-480Z'
                    />
                  </svg>
                </div>
                <div className='team__heading__controls--next'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -960 960 960'>
                    <path
                      fill='currentColor'
                      d='M517.847-480 354.924-642.924q-8.308-8.307-8.5-20.884-.193-12.576 8.5-21.268 8.692-8.693 21.076-8.693t21.076 8.693l179.769 179.769q5.616 5.615 7.923 11.846 2.308 6.23 2.308 13.461t-2.308 13.461q-2.307 6.231-7.923 11.846L397.076-274.924q-8.307 8.308-20.884 8.5-12.576.193-21.268-8.5-8.693-8.692-8.693-21.076t8.693-21.076L517.847-480Z'
                    />
                  </svg>
                </div>
              </div> */}
            </div>
          </div>
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            centeredSlides={false}
            loop={false}
            direction='horizontal'
            observeParents={true}
            parallax={true}
            navigation={{
              nextEl: '.team__heading__controls--next',
              prevEl: '.team__heading__controls--prev'
            }}
            breakpoints={{
              480: {
                slidesPerView: 3
              },
              1200: {
                slidesPerView: 5
              }
            }}
          >
            {team__items.map(([image, name, position, textPoints, linkedin], index) => {
              return (
                <SwiperSlide key={index}>
                  <TeamCard
                    image={String(image)}
                    name={String(name)}
                    position={String(position)}
                    textPoints={textPoints as string[]}
                    linkedin={String(linkedin)}
                  ></TeamCard>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>

        <section className='partners'>
          <div className='partners__wrapper'>
            <div className='global__heading align-center'>
              <h4 className='global__heading-title  brand-light--color size-m'>our partners</h4>
            </div>
            <div className='partners__box'>
              <img className='hide-md' loading='lazy' src={PartAll} alt='' />
              <img className='show-on-md' loading='lazy' src={PartAllM} alt='' />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
